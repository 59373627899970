<template>
  <div class="px-2">
    <SceneGraphNode
      :root-mode="true"
      :node="rootNode"
      :active-id="activeId"
      :gui="gui"
      @view="$emit('view', $event)"
      @focus="$emit('focus', $event)"
      @toggle-play="$emit('toggle-play', $event)"
    />
  </div>
</template>
<script>
const SceneGraphNode = () => import('./SceneGraphNode')

export default {

  components: {
    SceneGraphNode
  },
  props: {
    gui: {
      type: Object,
      required: false
    },
    rootNode: {
      type: Object,
      required: true
    },
    activeId: {
      default: null
    }
  }
}
</script></scrit>
